import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/labs/labs.mbanq.io/src/components/blog/Post.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`QC FinTech`}</h1>
    <p><a parentName="p" {...{
        "href": "/"
      }}>{`Mbanq Labs`}</a>{` is proud to announce a partnership with `}<a href="https://www.qcfintech.co/" target="_blank">{`Queen City Fintech`}</a>{`.`}</p>
    <a href="https://www.qcfintech.co/" target="_blank">Queen City Fintech</a> provides FinTech startups with access to world-class mentors, capital, and leading financial services organizations; all in the heart of global banking hub Charlotte, NC.
    <p>{`This is the first step in creating the global FinTech Accelerator Hub and we couldn't be happier to call `}<a href="https://www.qcfintech.co/" target="_blank">{`Queen City Fintech`}</a>{` our partner in US.`}</p>
    <a href="https://www.qcfintech.co/" target="_blank">Queen City Fintech</a> startups will enjoy <a href="/">Mbanq Labs</a> support in Southeast Asia and vice versa.
    <p>{`Let's create the `}<inlineCode parentName="p">{`Global FinTech Hub`}</inlineCode>{` together!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      